<template>
    <b-container class="my-4">
        <div class="search-section">
            <b-form-group
                label="Search By"
                class="mb-4"
                v-slot="{ searchTypeArea }"
            >
                <b-form-radio-group
                    @change="searchTypeChanged"
                    id="searchTypeSelection"
                    v-model="selectedSearchType"
                    :options="searchTypeOptions"
                    :aria-describedby="searchTypeArea"
                    name="search-term-options"
                ></b-form-radio-group>
            </b-form-group>
            <label>{{
                searchTypeOptions.find((i) => i.value == selectedSearchType)
                    .placeholderText
            }}</label>
            <b-row>
                <b-col cols="10">
                    <b-form-input
                        v-model="searchText"
                        :placeholder="
                            searchTypeOptions.find(
                                (i) => i.value == selectedSearchType
                            ).text
                        "
                    ></b-form-input>
                </b-col>
                <b-col cols="2" class="pl-0">
                    <b-button @click="search" variant="success">{{
                        searching ? "Searching" : "Search"
                    }}</b-button>
                </b-col>
            </b-row>
            <b-row v-if="searchFieldErrorMessage">
                <b-col cols="12">
                    <p class="text-danger error-message mt-1">
                        {{ searchFieldErrorMessage }}
                    </p>
                </b-col>
            </b-row>

            <div
                class="mt-4"
                v-if="
                    selectedSearchType == 'BusinessName' && searchResults.length
                "
            >
                <label>
                    Select A Record from the search results({{
                        searchResults.length - 1
                    }}
                    records)
                </label>
                <b-form-select
                    @change="fetchBusinessDetails('abn', selectedResult)"
                    v-model="selectedResult"
                    :options="searchResults"
                ></b-form-select>
            </div>
        </div>

        <b-form-group
            class="my-4"
            v-show="fieldIsFillable('zohocrm2abn__ABN')"
            label="ABN"
            label-for="abn-input"
        >
            <b-form-input
                id="abn-input"
                v-model="lookupData.Abn"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            class="my-4"
            v-show="fieldIsFillable('zohocrm2abn__ACN')"
            label="ACN"
            label-for="acn-input"
        >
            <b-form-input
                id="acn-input"
                v-model="lookupData.Acn"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            class="my-4"
            v-show="fieldIsFillable('zohocrm2abn__Entity_Name')"
            label="Entity Name"
            label-for="entity-name-input"
        >
            <b-form-input
                id="entity-name-input"
                v-model="lookupData.EntityName"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            class="my-4"
            v-show="fieldIsFillable('zohocrm2abn__ABN_Status')"
            label="ABN Status"
            label-for="abn-status-input"
        >
            <b-form-input
                id="abn-status-input"
                v-model="lookupData.AbnStatus"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            class="my-4"
            v-show="fieldIsFillable('zohocrm2abn__ABN_Status_Effective_From')"
            label="ABN Status Effective Date"
            label-for="AbnStatusEffectiveFrom-input"
        >
            <b-form-input
                id="AbnStatusEffectiveFrom-input"
                v-model="lookupData.AbnStatusEffectiveFrom"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            class="my-4"
            v-show="fieldIsFillable('zohocrm2abn__GST_From')"
            label="Goods & Services Tax(GST) from"
            label-for="gst-input"
        >
            <b-form-input
                id="gst-input"
                v-model="lookupData.Gst"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            v-show="fieldIsFillable('zohocrm2abn__Address')"
            class="my-4"
            label="Address"
            label-for="address-input"
        >
            <b-form-input
                id="address-input"
                class="mt-2"
                v-model="lookupData.address"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            class="my-4"
            v-show="fieldIsFillable('zohocrm2abn__First_Business_Name')"
            label="First Business Name"
            label-for="firstBusinessName-input"
        >
            <b-form-input
                id="firstBusinessName-input"
                v-model="lookupData.firstBusinessName"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            class="my-4"
            v-show="fieldIsFillable('zohocrm2abn__Business_Names')"
            label="Business Name"
            label-for="BusinessName-input"
        >
            <b-form-input
                id="BusinessName-input"
                v-model="lookupData.BusinessName"
            ></b-form-input>
        </b-form-group>
        <b-button
            @click="sync"
            variant="primary"
            class="btn-right-aligned sync-button"
            >Update CRM</b-button
        >
    </b-container>
</template>

<style scoped>
.search-section {
    padding-bottom: 24px;
    border-bottom: 1px solid #999;
}

.sync-button {
    padding: 0.375rem 2rem;
}

.btn-right-aligned {
    display: block;
    margin-left: auto;
}

.error-message {
    font-size: 0.75rem;
    margin-bottom: 0;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
export default {
    name: "vendors.search",
    data: () => ({
        moduleName: "Vendors",
        searchText: "",
        searchResults: [],
        selectedResult: null,
        selectedSearchType: "BusinessName",
        searchTypeOptions: [
            {
                text: "Business Name",
                placeholderText: "Type a Business Name",
                value: "BusinessName",
                apiLink: "https://abr.business.gov.au/json/MatchingNames.aspx",
            },
            {
                text: "ABN",
                placeholderText: "Type an ABN",
                value: "ABN",
                apiLink: "https://abr.business.gov.au/json/AbnDetails.aspx",
            },
            {
                text: "ACN",
                placeholderText: "Type an ACN",
                value: "ACN",
                apiLink: "https://abr.business.gov.au/json/AcnDetails.aspx",
            },
        ],

        searching: false,
        searchFieldErrorMessage: null,

        lookupData: {
            Abn: null,
            AbnStatus: null,
            AbnStatusEffectiveFrom: null,
            Acn: null,
            AddressDate: null,
            AddressPostcode: null,
            AddressState: null,
            BusinessName: null,
            EntityName: null,
            EntityTypeCode: null,
            EntityTypeName: null,
            Gst: null,
            Message: null,

            firstBusinessName: null,
            address: null,
        },
    }),
    computed: {
        settings() {
            return this.$store.getters.fillableSettings[this.moduleName];
        },
        genericSettings() {
            return this.$store.getters.fillableSettings.genericSettings;
        },
    },
    async mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.searchText = this.$store.getters.entityData.data.Vendor_Name;
        },
        search() {
            if (!this.searchText) {
                this.searchFieldErrorMessage =
                    "Please provide the " +
                    this.searchTypeOptions.find(
                        (i) => i.value == this.selectedSearchType
                    ).text;
                console.log(this.searchFieldErrorMessage);
                return;
            }

            if (this.selectedSearchType == "ABN") {
                this.fetchBusinessDetails("abn", this.searchText);
                return;
            } else if (this.selectedSearchType == "ACN") {
                this.fetchBusinessDetails("acn", this.searchText);
                return;
            }

            const params = {
                name: this.searchText,
                maxResults: 10,
                callback: "window.processAbrSearchData",
                guid: process.env.VUE_APP_GUID,
            };
            this.searching = true;

            axios
                .get("https://abr.business.gov.au/json/MatchingNames.aspx", {
                    params,
                })
                .then((response) => {
                    window.processAbrSearchData = (data) => {
                        this.searchResults = data.Names.map((i) => ({
                            value: i.Abn,
                            text: `${i.Abn} ${i.Name} ${i.NameType} ${i.Postcode} ${i.State}`,
                        }));
                        this.searchResults.unshift({
                            value: null,
                            text: "Please Select a Result",
                        });
                    };
                    const functionCall = new Function(response.data);
                    functionCall();
                    this.searching = false;
                })
                .catch(() => {
                    this.searchFieldErrorMessage =
                        "Please provide a valid " +
                        this.searchTypeOptions.find(
                            (i) => i.value == this.selectedSearchType
                        ).text;
                    this.searching = false;
                });
        },
        fetchBusinessDetails(type, identity) {
            if (!identity) return;

            const params = {
                callback: "window.processAbnLookupData",
                guid: process.env.VUE_APP_GUID,
            };

            var apiLink;
            if (type == "abn") {
                params.abn = identity;
                apiLink = "https://abr.business.gov.au/json/AbnDetails.aspx";
            } else if (type == "acn") {
                params.acn = identity;
                apiLink = "https://abr.business.gov.au/json/AcnDetails.aspx";
            }

            axios
                .get(apiLink, { params })
                .then((response) => {
                    window.processAbnLookupData = (data) => {
                        this.lookupData.Abn = data.Abn;
                        this.lookupData.AbnStatus = data.AbnStatus;
                        this.lookupData.AbnStatusEffectiveFrom =
                            data.AbnStatusEffectiveFrom;
                        this.lookupData.Acn = data.Acn;
                        this.lookupData.AddressDate = data.AddressDate;
                        this.lookupData.AddressPostcode = data.AddressPostcode;
                        this.lookupData.AddressState = data.AddressState;
                        this.lookupData.BusinessName =
                            data.BusinessName.join(", ");
                        this.lookupData.EntityName = data.EntityName;
                        this.lookupData.EntityTypeCode = data.EntityTypeCode;
                        this.lookupData.EntityTypeName = data.EntityTypeName;
                        this.lookupData.Gst = data.Gst;
                        this.lookupData.Message = data.Message;

                        this.lookupData.address =
                            data.AddressState + " " + data.AddressPostcode;
                        this.lookupData.firstBusinessName = data.BusinessName
                            .length
                            ? data.BusinessName[0]
                            : "";
                    };

                    const functionCall = new Function(response.data);
                    functionCall();
                    this.searching = false;
                })
                .catch(() => {
                    this.searchFieldErrorMessage =
                        "Please provide a valid " +
                        this.searchTypeOptions.find(
                            (i) => i.value == this.selectedSearchType
                        ).text;
                    this.searching = false;
                });
        },
        fieldIsFillable(fieldName) {
            return this.settings.find((i) => i.api_name == fieldName).value;
        },
        async sync() {
            var payload = {};
            if (this.fieldIsFillable("zohocrm2abn__ABN")) {
                payload.zohocrm2abn__ABN = this.lookupData.Abn;
            }
            if (this.fieldIsFillable("zohocrm2abn__ACN")) {
                payload.zohocrm2abn__ACN = this.lookupData.Acn;
            }
            if (this.fieldIsFillable("zohocrm2abn__Entity_Name")) {
                payload.zohocrm2abn__Entity_Name = this.lookupData.EntityName;
            }
            if (this.fieldIsFillable("zohocrm2abn__ABN_Status")) {
                payload.zohocrm2abn__ABN_Status = this.lookupData.AbnStatus;
            }
            if (
                this.fieldIsFillable("zohocrm2abn__ABN_Status_Effective_From")
            ) {
                payload.zohocrm2abn__ABN_Status_Effective_From = this.lookupData
                    .AbnStatusEffectiveFrom
                    ? moment(this.lookupData.AbnStatusEffectiveFrom).format(
                          this.genericSettings.dateFormat
                      )
                    : "";
            }
            if (this.fieldIsFillable("zohocrm2abn__GST_From")) {
                payload.zohocrm2abn__GST_From = this.lookupData.Gst
                    ? moment(this.lookupData.Gst).format(
                          this.genericSettings.dateFormat
                      )
                    : "";
            }
            if (this.fieldIsFillable("zohocrm2abn__Address")) {
                payload.zohocrm2abn__Address = this.lookupData.address;
            }
            if (this.fieldIsFillable("zohocrm2abn__First_Business_Name")) {
                payload.zohocrm2abn__First_Business_Name =
                    this.lookupData.firstBusinessName;
            }
            if (this.fieldIsFillable("zohocrm2abn__Business_Names")) {
                payload.zohocrm2abn__Business_Names =
                    this.lookupData.BusinessName;
            }

            ZOHO.CRM.UI.Record.populate(payload).then((data) => {
                ZOHO.CRM.UI.Popup.close().then((data) => {});
            });
        },
        searchTypeChanged() {
            this.searchText = "";
        },
    },
};
</script>
